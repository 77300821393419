<template>
	<div class="exb-logos" v-show="exhibitors && exhibitors.published">
        <pre v-if="0">{{ exhibitorsFiltered }}</pre>
        <div class="images">
            <figure v-for="(exb, index) in exhibitorsFiltered" :key="'exb-'+index" class="wp-block-image size-full" :title="'Logo von '+exb.title">
                <img loading="lazy" decoding="async" width="225" height="175" :src="exb.logo.src" :alt="'Logo von '+exb.title" class="wp-image-930">
            </figure>
        </div>
	</div>
</template>

<script>

import axios from 'axios';

export default {
	name: 'webComponent',
	components:{},
    props:{
        file: {
            type: String,
            default: "https://duesseldorfer-diabetestag.de/wp-content/plugins/ddt-admin/api/getExhibitors"
        }
    },
	data: function () {
        
		return {
            axios,
            url:this.file,
            loading:true,
            testProgressAnimation:0, // ms | false | 0
            error:false,
			exhibitors:false,
			roomIndex:0,
            window:{
                width:0,
                height:0
            },
            logosWidth:225,
            gapWidth:50,
            numLogosVisible:4,
		}
	},
    async created(){
        this.exhibitors = await this.load();
        this.loading = false;

        window.addEventListener("resize", this.windowResized);
        this.windowResized();
    },

    destroyed() {
        window.removeEventListener("resize", this.windowResized);
    },

    methods:{
        async load(){
            
            var response;
            try {
                response = await axios.post(this.url);
                
            } catch (error) {
                response = false;
                console.log(error);
            }

            console.log("response", response);
            return new Promise((resolve, reject)=>{
                if( ! response || typeof response.data == 'undefined' )
                {
                    this.error = "Die Datendatei enthält kein gültiges JSON-Format.";
                    reject();
                }

                (async () => {
                    if( this.testProgressAnimation )
                        await new Promise((res) => setTimeout(res, this.testProgressAnimation));
                    resolve(response.data);
                })();
            
                return true;
            });
        },

        windowResized(){

            // var c = this.$refs.carousel;
            // c.pause();
            // this.window.width = window.innerWidth;
            // this.window.height = window.innerHeight;
            // //console.log( JSON.stringify(this.window));

            // this.numLogosVisible = Math.floor( window.innerWidth/(this.logosWidth+this.gapWidth) );

            // //c.setSlide(0);
            // c.start();
        },

        shuffle(array) {
            var currentIndex = array.length,  randomIndex;
            
            // While there remain elements to shuffle...
            while (0 !== currentIndex) {
                
                // Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex--;
                
                // And swap it with the current element.
                [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
            }
            
            return array;
        }
    },

    computed:{
        
        exhibitorsFiltered() {
            if(! this.exhibitors )
                return [];

            const items = this.exhibitors.items;

            const filtered = [];
            for (let i = 0; i < items.length; i++){
                var item = items[i];
                if( item.active && (item.packageId > 1 || item.showLogo) )
                    filtered.push(item);    
            }
            return filtered;
        },

        // chunked() {
        //     if(! this.exhibitors )
        //         return [];

        //     const items = this.shuffle(this.exhibitors.items);

        //     const result = []
        //     for (let i = 0; i < items.length; i += this.numLogosVisible)
        //         result.push(items.slice(i, i + this.numLogosVisible))
        //     return result;
        // },

        // exhibitorsSorted(){

        //     if(! this.exhibitors)
        //         return [];

        //     var premium = this.exhibitors.items.filter((exb) => {
        //         return exb.packageId > 0;
        //     }).sort((a, b) => {
        //         if (a.title < b.title) return -1;
        //         if (a.title > b.title) return 1;
        //         return 0;
        //     });

        //     var standard = this.exhibitors.items.filter((exb) => {
        //         return exb.packageId == 0;
        //     }).sort((a, b) => {
        //         if (a.title < b.title) return -1;
        //         if (a.title > b.title) return 1;
        //         return 0;
        //     });

        //     return premium.concat(standard);
        // },
    }
}
</script>

<style type="text/css">

.exb-logos{
    /* background-color: #EEE; */
}

.exb-logos figure{
    margin:0px;
    margin-block: 0px;
    margin-inline: 0px;
    display:inline-flex;
}

.exb-logos .images{
    display:flex;
    flex-wrap:wrap;
    gap:20px;
    align-items: center;
    justify-content: center;
}

.exb-logos .alert{
    display:inline-block;
    padding:30px;
}

.exb-logos .alert-primary{
    background-color: #eaf4f4;
    border-color: #cddddd;
    color:black;
}




@media (min-width: 800px) {
	
    /* .exhibitor:not(.premium){
        width: calc(50% - 20px);
        
        
    } */
}

@media (max-width: 799px) {
	
    /* .exhibitor .logo{
        width:100% !important;
        height:140px !important;
        float:none;
        margin-left:0;
        margin-bottom:1em;
    } */
}




/* .item-container {
	padding:15px;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	gap:20px;
}

.item-container .item {
	width: 100%;
    height:auto;
	color:inherit;
}

.item-container .item.package-2{
    height:300px;
}

@media (min-width: 600px) {
	
	.item-container .item{
		width: calc((100% - (1 * 20px)) / 2);
	} 
}

@media (min-width: 800px) {
	.item-container {
		padding:40px;
	}
	.item-container .item{
		width: calc((100% - (2 * 20px)) / 3);
	}
}


@media (min-width: 1100px) {
	.item-container .item{
		width: calc((100% - (3 * 20px)) / 4);
	}
}

@media (min-width: 1400px) {
	.item-container .item{
		width: calc((100% - (4 * 20px)) / 5);
	}
}

@media (min-width: 1700px) {
	.item-container .item{
		width: calc((100% - (5 * 20px)) / 6);
	}
}

.item-container {
	padding-top:40px;
	padding-bottom:0px;
} */


</style>